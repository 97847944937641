import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Container, Button, Menu, MenuItem, useMediaQuery, Avatar } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Database from './pages/Database';
import Games from './pages/Games';
import GameDetail from './pages/GameDetail';
import SystemDetail from './pages/SystemDetail';
import Profile from './pages/Profile';
import MyCollection from './pages/MyCollection';
import { useTheme } from './ThemeContext';
import './App.css';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import { useAuth } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import { auth, signOutUser } from './firebase';

function App() {
  const { theme } = useTheme();
  const isLightTheme = theme === 'light';
  const muiTheme = useMuiTheme();
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();

  const [anchorEl, setAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  const handleLogout = () => {
    signOutUser();
    handleProfileMenuClose();
  };

  const getInitials = (name) => {
    if (!name) return '';
    const namesArray = name.split(' ');
    const initials = namesArray.map(namePart => namePart[0]).join('');
    return initials.toUpperCase();
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user && location.pathname === '/') {
        navigate('/mycollection');
      }
    });

    return () => unsubscribe();
  }, [navigate, location.pathname]);

  return (
    <div>
      {currentUser && (
      <AppBar position="static" sx={{ backgroundColor: isLightTheme ? muiTheme.palette.primary.main : muiTheme.palette.background.paper }}>
        <Toolbar>
          <Typography 
            variant="h6" 
            sx={{ flexGrow: 1, color: isLightTheme ? muiTheme.palette.common.white : muiTheme.palette.text.primary }}
            component={Link} 
            to="/" 
            style={{ textDecoration: 'none', color: 'inherit' }} 
          >
            PIXELATED GAMING
          </Typography>
          {isSmallScreen ? (
            <>
              {currentUser && (
                <IconButton color="inherit" onClick={handleMenuOpen}>
                  <MenuIcon />
                </IconButton>
              )}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {currentUser && (
                  <MenuItem component={Link} to="/mycollection" onClick={handleMenuClose}>
                    My Collection
                  </MenuItem>
                )}
                {currentUser && (
                  <MenuItem component={Link} to="/database" onClick={handleMenuClose}>
                    Games Database
                  </MenuItem>
                )}
                {currentUser && (
                  <MenuItem component={Link} to="/profile" onClick={handleMenuClose}>
                    My Profile
                  </MenuItem>
                )}
                {currentUser && (
                  <MenuItem onClick={handleLogout}>
                    Logout
                  </MenuItem>
                )}
              </Menu>
            </>
          ) : (
            <>
              {currentUser && <Button color="inherit" component={Link} to="/mycollection">My Collection</Button>}
              {currentUser && <Button color="inherit" component={Link} to="/database">Games Database</Button>}
              {currentUser && (
                <>
                  <IconButton color="inherit" onClick={handleProfileMenuOpen}>
                    <Avatar sx={{ bgcolor: muiTheme.palette.secondary.main }}>
                      {getInitials(currentUser.displayName)}
                    </Avatar>
                  </IconButton>
                  <Menu
                    anchorEl={profileAnchorEl}
                    open={Boolean(profileAnchorEl)}
                    onClose={handleProfileMenuClose}
                  >
                    <MenuItem component={Link} to="/profile" onClick={handleProfileMenuClose}>My Profile</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>)}
      <Container>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/database" element={<PrivateRoute><Database /></PrivateRoute>} />
          <Route path="/games/:systemId" element={<Games />} />
          <Route path="/game/:gameId" element={<GameDetail />} />
          <Route path="/system/:systemId" element={<SystemDetail />} />
          <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="/mycollection" element={<PrivateRoute><MyCollection /></PrivateRoute>} />
        </Routes>
      </Container>
    </div>
  );
}

export default App;
