import React from 'react';
import styled from 'styled-components';

const PricingSection = styled.section`
  padding: 5em 0;
  background: #fff;
  text-align: center;
`;

const PricingTable = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const PriceOption = styled.div`
  margin: 1em;
  padding: 2em;
  border: 2px solid #ddd;
  border-radius: 10px;
  width: 250px;
  background: #f3f3f3;
  transition: border-color 0.3s;
  &:hover {
    border-color: #ff6b6b;
  }
`;

const Price = styled.div`
  font-size: 2rem;
  margin: 1em 0;
`;

const PriceButton = styled.button`
  padding: 1em 2em;
  background: #ff6b6b;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background 0.3s;
  &:hover {
    background: #ff4757;
  }
`;

const Pricing = ({ handleGetStarted }) => (
  <PricingSection id="pricing">
    <h2>Pricing</h2>
    <PricingTable>
      <PriceOption>
        <h3>Monthly</h3>
        <Price>FREE</Price>
        <PriceButton onClick={handleGetStarted}>Subscribe Now</PriceButton>
      </PriceOption>
      <PriceOption>
        <h3>Yearly</h3>
        <Price>FREE</Price>
        <PriceButton onClick={handleGetStarted}>Subscribe Now</PriceButton>
      </PriceOption>
    </PricingTable>
  </PricingSection>
);

export default Pricing;
