import React from 'react';
import styled from 'styled-components';
import { FaGamepad, FaList, FaDatabase } from 'react-icons/fa';

const FeaturesSection = styled.section`
  padding: 5em 0;
  background: #f9f9f9;
  text-align: center;
`;

const Feature = styled.div`
  margin: 2em;
  display: inline-block;
  width: 30%;
  vertical-align: top;
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  color: #ff6b6b;
`;

const FeatureTitle = styled.h2`
  margin: 1em 0;
`;

const FeatureDescription = styled.p`
  color: #555;
`;

const Features = () => (
  <FeaturesSection id="features">
    <Feature>
      <FeatureIcon><FaGamepad /></FeatureIcon>
      <FeatureTitle>Collection Management</FeatureTitle>
      <FeatureDescription>Easily catalog your retro games collection, including all your favorite titles from various classic systems.</FeatureDescription>
    </Feature>
    <Feature>
      <FeatureIcon><FaList /></FeatureIcon>
      <FeatureTitle>Wishlist</FeatureTitle>
      <FeatureDescription>Create and manage a wishlist of games you want to add to your library.</FeatureDescription>
    </Feature>
    <Feature>
      <FeatureIcon><FaDatabase /></FeatureIcon>
      <FeatureTitle>Comprehensive Database</FeatureTitle>
      <FeatureDescription>Access detailed information about every game and system. Explore and find new retro games to add to your collection. Keep track of what you own and what you want to play.</FeatureDescription>
    </Feature>
  </FeaturesSection>
);

export default Features;
