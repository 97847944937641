import React from 'react';
import { Helmet } from 'react-helmet';
import { Typography, Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { signInWithGoogle } from '../firebase';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Pricing from '../components/Pricing';

const Home = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleGetStarted = () => {
    if (currentUser) {
      navigate('/mycollection');
    } else {
      signInWithGoogle(() => {
        navigate('/mycollection');
      });
    }
  };

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Pixelated Gaming - Manage Your Retro Games Collection</title>
        <meta name="description" content="Pixelated Gaming is a web app to manage the retro games collection for gamers who love retrogaming. Discover, organize, and enjoy your retro games collection all in one place." />
        <meta name="keywords" content="retro games, gaming, collection, manage games, Pixelated Gaming" />
      </Helmet>

      <Hero handleGetStarted={handleGetStarted} />
      
      <Box my={4} textAlign="center">
        <Typography variant="h2" component="h2" gutterBottom>
          Welcome to Pixelated Gaming
        </Typography>
        <Typography variant="h5" component="p" gutterBottom>
          Pixelated Gaming is a web app to manage the retro games collection for gamers who love retrogaming.
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Discover, organize, and enjoy your retro games collection all in one place.
        </Typography>
      </Box>

      <Features />
      <Pricing handleGetStarted={handleGetStarted} />

    </Container>
  );
};

export default Home;
