// src/pages/GameDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchGameById } from '../firebase-utils';
import { Typography, Container } from '@mui/material';

const GameDetail = () => {
  const { gameId } = useParams();
  const [game, setGame] = useState(null);

  useEffect(() => {
    const getGame = async () => {
      const gameData = await fetchGameById(gameId);
      setGame(gameData);
    };

    getGame();
  }, [gameId]);

  if (!game) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container>
      <Typography variant="h1">{game.title}</Typography>
      <Typography variant="h2">Developer: {game.developer}</Typography>
      <Typography variant="h3">Publisher: {game.publisher}</Typography>
      {/* Add more details as needed */}
    </Container>
  );
};

export default GameDetail;