import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  Typography,
  Box,
  Container,
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  CardActions,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import {
  fetchGamesBySystem,
  addItemToCollection,
  fetchUserCollection,
  fetchCollectionItems,
} from '../firebase-utils';
import { useAuth } from '../contexts/AuthContext';

const Games = () => {
  const { systemId } = useParams();
  const location = useLocation();
  const { currentUser } = useAuth();
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [orderBy, setOrderBy] = useState('title');
  const [orderDirection, setOrderDirection] = useState('asc');
  const [userCollectionItems, setUserCollectionItems] = useState([]);
  const systemNameFromState = location.state?.systemName || '';

  useEffect(() => {
    const getGames = async () => {
      try {
        const gamesData = await fetchGamesBySystem(systemId);
        setGames(gamesData);
      } catch (error) {
        console.error('Error fetching games: ', error);
      } finally {
        setLoading(false);
      }
    };

    const getUserCollectionItems = async () => {
      try {
        if (currentUser) {
          const userCollection = await fetchUserCollection(currentUser.uid);
          const collectionItems = await fetchCollectionItems(userCollection.id);
          setUserCollectionItems(collectionItems);
        }
      } catch (error) {
        console.error('Error fetching user collection items: ', error);
      }
    };

    getGames();
    getUserCollectionItems();
  }, [systemId, currentUser]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOrderByChange = (event) => {
    setOrderBy(event.target.value);
  };

  const handleOrderDirectionChange = (event) => {
    setOrderDirection(event.target.value);
  };

  const handleAddToCollection = async (gameId) => {
    try {
      if (!currentUser) {
        console.error('User not authenticated');
        return;
      }
      const userCollection = await fetchUserCollection(currentUser.uid);
      await addItemToCollection(userCollection.id, 'game', gameId);
      const updatedCollectionItems = await fetchCollectionItems(userCollection.id);
      setUserCollectionItems(updatedCollectionItems);
    } catch (error) {
      console.error('Error adding game to collection:', error);
    }
  };

  const isGameInCollection = (gameId) => {
    return userCollectionItems.some((item) => item.itemType === 'game' && item.itemId === gameId);
  };

  const filterGames = (games, query) => {
    if (!query) {
      return games;
    }
    return games.filter((game) => {
      const queryLower = query.toLowerCase();
      return (
        game.title.toLowerCase().includes(queryLower) ||
        game.developer.toLowerCase().includes(queryLower) ||
        game.publisher.toLowerCase().includes(queryLower) ||
        game.year.toString().includes(queryLower)
      );
    });
  };

  const sortGames = (games, orderBy, orderDirection) => {
    return games.sort((a, b) => {
      if (orderBy === 'year') {
        return orderDirection === 'asc' ? a.year - b.year : b.year - a.year;
      } else {
        const nameA = a[orderBy].toLowerCase();
        const nameB = b[orderBy].toLowerCase();
        if (nameA < nameB) {
          return orderDirection === 'asc' ? -1 : 1;
        }
        if (nameA > nameB) {
          return orderDirection === 'asc' ? 1 : -1;
        }
        return 0;
      }
    });
  };

  const filteredGames = filterGames(games, searchQuery);
  const sortedGames = sortGames(filteredGames, orderBy, orderDirection);

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h1" component="h2" gutterBottom>
          Games for {systemNameFromState || (games.length > 0 ? games[0].systemName : 'Loading...')}
        </Typography>
      </Box>

      <Box my={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Search Games"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="order-by-label">Order By</InputLabel>
              <Select
                labelId="order-by-label"
                value={orderBy}
                onChange={handleOrderByChange}
                label="Order By"
              >
                <MenuItem value="title">Name</MenuItem>
                <MenuItem value="year">Year</MenuItem>
                <MenuItem value="developer">Developer</MenuItem>
                <MenuItem value="publisher">Publisher</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="order-direction-label">Direction</InputLabel>
              <Select
                labelId="order-direction-label"
                value={orderDirection}
                onChange={handleOrderDirectionChange}
                label="Direction"
              >
                <MenuItem value="asc">A-Z / Ascending</MenuItem>
                <MenuItem value="desc">Z-A / Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      
      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <Typography variant="h6" component="p" gutterBottom>
            Loading...
          </Typography>
        </Box>
      ) : (
        <Box my={4}>
          {sortedGames.length === 0 ? (
            <Typography variant="h6" component="p" gutterBottom>
              No games available for {systemNameFromState}
            </Typography>
          ) : (
            <>
              <Typography variant="body2" component="p" gutterBottom>
                Total games: {sortedGames.length}
              </Typography>
              <Grid container spacing={3}>
                {sortedGames.map((game) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={game.id}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" component="div">
                          {game.title}
                        </Typography>
                        <Typography color="text.secondary">
                          {game.developer}
                        </Typography>
                        <Typography color="text.secondary">
                          {game.publisher}
                        </Typography>
                        <Typography color="text.secondary">
                          {game.year}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => handleAddToCollection(game.id)}
                          disabled={isGameInCollection(game.id)}
                        >
                          {isGameInCollection(game.id) ? 'In Collection' : 'Add to Collection'}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Box>
      )}
    </Container>
  );
};

export default Games;
