// src/pages/SystemDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchSystemById } from '../firebase-utils';
import { Typography, Container } from '@mui/material';

const SystemDetail = () => {
  const { systemId } = useParams();
  const [system, setSystem] = useState(null);

  useEffect(() => {
    const getSystem = async () => {
      const systemData = await fetchSystemById(systemId);
      setSystem(systemData);
    };

    getSystem();
  }, [systemId]);

  if (!system) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container>
      <Typography variant="h1">{system.name}</Typography>
      <Typography variant="h2">Manufacturer: {system.manufacturer}</Typography>
      {/* Add more details as needed */}
    </Container>
  );
};

export default SystemDetail;