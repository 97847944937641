import React from 'react';
import styled from 'styled-components';

const HeroSection = styled.section`
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const HeroTitle = styled.h1`
  font-size: 3.5rem;
  backdrop-filter: blur(5px);
  padding: 0.5em 1em;
`;

const HeroButton = styled.button`
  margin-top: 1.5em;
  padding: 1em 2em;
  background: #ff6b6b;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 5px;
  transition: background 0.3s;
  &:hover {
    background: #ff4757;
  }
`;

const Hero = ({ handleGetStarted }) => (
  <HeroSection>
    <HeroTitle>Pixelated Gaming - Manage Your Retro Games Collection</HeroTitle>
    <HeroButton onClick={handleGetStarted}>
      Get Started
    </HeroButton>
  </HeroSection>
);

export default Hero;
