import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Box,
  Container,
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  CardActions,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import {
  fetchSystems,
  addItemToCollection,
  fetchUserCollection,
  fetchCollectionItems,
} from '../firebase-utils';
import { useAuth } from '../contexts/AuthContext';

const Database = () => {
  const { currentUser } = useAuth();
  const [systems, setSystems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [orderBy, setOrderBy] = useState('name');
  const [orderDirection, setOrderDirection] = useState('asc');
  const [userCollectionItems, setUserCollectionItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getSystems = async () => {
      try {
        const systemsData = await fetchSystems();
        setSystems(systemsData);
      } catch (error) {
        console.error('Error fetching systems: ', error);
      } finally {
        setLoading(false);
      }
    };

    const getUserCollectionItems = async () => {
      try {
        if (currentUser) {
          const userCollection = await fetchUserCollection(currentUser.uid);
          const collectionItems = await fetchCollectionItems(userCollection.id);
          setUserCollectionItems(collectionItems);
        }
      } catch (error) {
        console.error('Error fetching user collection items: ', error);
      }
    };

    getSystems();
    getUserCollectionItems();
  }, [currentUser]);

  const handleGamesClick = (systemId, systemName) => {
    navigate(`/games/${systemId}`, { state: { systemName } });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOrderByChange = (event) => {
    setOrderBy(event.target.value);
  };

  const handleOrderDirectionChange = (event) => {
    setOrderDirection(event.target.value);
  };

  const handleAddToCollection = async (systemId) => {
    try {
      if (!currentUser) {
        console.error('User not authenticated');
        return;
      }
      const userCollection = await fetchUserCollection(currentUser.uid);
      await addItemToCollection(userCollection.id, 'system', systemId);
      const updatedCollectionItems = await fetchCollectionItems(userCollection.id);
      setUserCollectionItems(updatedCollectionItems);
    } catch (error) {
      console.error('Error adding system to collection:', error);
    }
  };

  const isSystemInCollection = (systemId) => {
    return userCollectionItems.some((item) => item.itemType === 'system' && item.itemId === systemId);
  };

  const filterSystems = (systems, query) => {
    if (!query) {
      return systems;
    }
    return systems.filter((system) => {
      const queryLower = query.toLowerCase();
      return (
        system.name.toLowerCase().includes(queryLower) ||
        system.manufacturer.toLowerCase().includes(queryLower)
      );
    });
  };

  const sortSystems = (systems, orderBy, orderDirection) => {
    return systems.sort((a, b) => {
      const valueA = a[orderBy].toLowerCase();
      const valueB = b[orderBy].toLowerCase();
      if (valueA < valueB) {
        return orderDirection === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const filteredSystems = filterSystems(systems, searchQuery);
  const sortedSystems = sortSystems(filteredSystems, orderBy, orderDirection);

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h1" component="h2" gutterBottom>
          Games Database
        </Typography>
      </Box>

      <Box my={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Search Systems"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="order-by-label">Order By</InputLabel>
              <Select
                labelId="order-by-label"
                value={orderBy}
                onChange={handleOrderByChange}
                label="Order By"
              >
                <MenuItem value="name">Name</MenuItem>
                <MenuItem value="manufacturer">Manufacturer</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="order-direction-label">Direction</InputLabel>
              <Select
                labelId="order-direction-label"
                value={orderDirection}
                onChange={handleOrderDirectionChange}
                label="Direction"
              >
                <MenuItem value="asc">A-Z / Ascending</MenuItem>
                <MenuItem value="desc">Z-A / Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <Typography variant="h6" component="p" gutterBottom>
            Loading...
          </Typography>
        </Box>
      ) : (
        <Box my={4}>
          {sortedSystems.length === 0 ? (
            <Typography variant="h6" component="p" gutterBottom>
              No systems available
            </Typography>
          ) : (
            <>
              <Typography variant="body2" component="p" gutterBottom>
                Total systems: {sortedSystems.length}
              </Typography>
              <Grid container spacing={3}>
                {sortedSystems.map((system) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={system.id}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" component="div">
                          {system.name}
                        </Typography>
                        <Typography color="text.secondary">
                          {system.manufacturer}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small" onClick={() => handleGamesClick(system.id, system.name)}>
                          View Games
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => handleAddToCollection(system.id)}
                          disabled={isSystemInCollection(system.id)}
                        >
                          {isSystemInCollection(system.id) ? 'In Collection' : 'Add to Collection'}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Box>
      )}
    </Container>
  );
};

export default Database;
