// src/ThemeContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from './theme';
import { useAuth } from './contexts/AuthContext';
import { fetchUserByUid, updateUserTheme } from './firebase-utils';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    const fetchTheme = async () => {
      if (currentUser) {
        try {
          const userData = await fetchUserByUid(currentUser.uid);
          setTheme(userData.theme || 'light');
        } catch (error) {
          console.error('Error fetching user theme:', error);
        }
      } else {
        setTheme('light');  // Reset to light theme on logout
      }
    };
    fetchTheme();
  }, [currentUser]);

  const toggleTheme = async () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);

    if (currentUser) {
      try {
        await updateUserTheme(currentUser.uid, newTheme);
      } catch (error) {
        console.error('Error updating user theme:', error);
      }
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <MuiThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
