import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchGames = async () => {
  const response = await axios.get(`${API_URL}/games`);
  return response.data;
};

export const fetchSystems = async () => {
  const response = await axios.get(`${API_URL}/systems`);
  return response.data;
};

export const fetchGameById = async (id) => {
  const response = await axios.get(`${API_URL}/games/${id}`);
  return response.data;
};

export const fetchSystemById = async (id) => {
  const response = await axios.get(`${API_URL}/systems/${id}`);
  return response.data;
};

export const fetchGamesBySystem = async (systemId) => {
  const response = await axios.get(`${API_URL}/games`, {
    params: {
      systemId: systemId
    }
  });
  return response.data;
};

// Upsert user data
export const loginUser = async (userData) => {
  const response = await axios.post(`${API_URL}/users/login`, userData);
  return response.data;
};

// Fetch user data by UID
export const fetchUserByUid = async (uid) => {
  const response = await axios.get(`${API_URL}/users/${uid}`);
  return response.data;
};

// Update user theme preference
export const updateUserTheme = async (uid, theme) => {
  const response = await axios.put(`${API_URL}/users/${uid}/theme`, { theme });
  return response.data;
};

// Fetch or create user collection
export const fetchUserCollection = async (userId) => {
  const response = await axios.get(`${API_URL}/collections/user/${userId}`);
  return response.data;
};

// Fetch collection items by collectionId
export const fetchCollectionItems = async (collectionId) => {
  const response = await axios.get(`${API_URL}/collections/${collectionId}/items`);
  return response.data;
};

// Add item to collection
export const addItemToCollection = async (collectionId, itemType, itemId) => {
  const response = await axios.post(`${API_URL}/collectionitems/additem`, { collectionId, itemType, itemId });
  return response.data;
};

// Delete item from collection
export const deleteItemFromCollection = async (collectionItemId) => {
  const response = await axios.delete(`${API_URL}/collectionitems/deleteitem/${collectionItemId}`);
  return response.data;
};
