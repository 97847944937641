import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid, Button, Card, CardContent, CardActions, MenuItem, Select, FormControl, InputLabel, TextField } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { fetchUserCollection, fetchCollectionItems, fetchGameById, fetchSystemById, deleteItemFromCollection, fetchSystems } from '../firebase-utils';

const MyCollection = () => {
  const { currentUser } = useAuth();
  const [collection, setCollection] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState([]);
  const [systems, setSystems] = useState([]);
  const [selectedSystem, setSelectedSystem] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [orderBy, setOrderBy] = useState('title');
  const [orderDirection, setOrderDirection] = useState('asc');

  useEffect(() => {
    const getUserCollection = async () => {
      try {
        if (currentUser) {
          const userCollection = await fetchUserCollection(currentUser.uid);
          setCollection(userCollection);
        }
      } catch (error) {
        console.error('Error fetching user collection:', error);
      } finally {
        setLoading(false);
      }
    };

    const getSystems = async () => {
      try {
        const systemsData = await fetchSystems();
        const sortedSystemsData = systemsData.sort((a, b) => a.name.localeCompare(b.name));
        setSystems(sortedSystemsData);
      } catch (error) {
        console.error('Error fetching systems:', error);
      }
    };

    getUserCollection();
    getSystems();
  }, [currentUser]);

  useEffect(() => {
    if (collection) {
      const getCollectionItems = async () => {
        try {
          const collectionItems = await fetchCollectionItems(collection.id);
          setItems(collectionItems);
        } catch (error) {
          console.error('Error fetching collection items:', error);
        }
      };

      getCollectionItems();
    }
  }, [collection]);

  useEffect(() => {
    const getDetails = async () => {
      try {
        const detailsPromises = items.map(async (item) => {
          if (item.itemType === 'game') {
            const game = await fetchGameById(item.itemId);
            return { ...game, itemType: 'game', collectionItemId: item.id };
          } else if (item.itemType === 'system') {
            const system = await fetchSystemById(item.itemId);
            return { ...system, itemType: 'system', collectionItemId: item.id };
          }
          return null;
        });

        const details = await Promise.all(detailsPromises);
        setDetails(details.filter(detail => detail !== null));
      } catch (error) {
        console.error('Error fetching item details:', error);
      }
    };

    if (items.length > 0) {
      getDetails();
    }
  }, [items]);

  const handleDelete = async (collectionItemId) => {
    try {
      await deleteItemFromCollection(collectionItemId);
      setDetails(details.filter(detail => detail.collectionItemId !== collectionItemId));
    } catch (error) {
      console.error('Error deleting item from collection:', error);
    }
  };

  const handleSystemFilterChange = (event) => {
    setSelectedSystem(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOrderByChange = (event) => {
    setOrderBy(event.target.value);
  };

  const handleOrderDirectionChange = (event) => {
    setOrderDirection(event.target.value);
  };

  const filterGames = (games, query) => {
    if (!query) {
      return games;
    }
    const queryLower = query.toLowerCase();
    return games.filter((game) => {
      return (
        game.title.toLowerCase().includes(queryLower) ||
        game.developer.toLowerCase().includes(queryLower) ||
        game.publisher.toLowerCase().includes(queryLower) ||
        game.year.toString().includes(queryLower) ||
        game.systemName.toLowerCase().includes(queryLower)
      );
    });
  };

  const filterSystems = (systems, selectedSystem) => {
    if (!selectedSystem) {
      return systems;
    }
    return systems.filter(system => system.id === selectedSystem);
  };

  const sortItems = (items, orderBy, orderDirection) => {
    return items.sort((a, b) => {
      const valueA = a[orderBy]?.toString().toLowerCase() || '';
      const valueB = b[orderBy]?.toString().toLowerCase() || '';
      if (valueA < valueB) {
        return orderDirection === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const filteredGames = filterGames(details.filter(detail => detail.itemType === 'game' && (!selectedSystem || detail.systemId === selectedSystem)), searchQuery);
  const sortedGames = sortItems(filteredGames, orderBy, orderDirection);
  const filteredSystems = filterSystems(details.filter(detail => detail.itemType === 'system'), selectedSystem);
  const sortedSystems = sortItems(filteredSystems, 'name', 'asc');

  if (loading) {
    return (
      <Box my={4}>
        <Typography variant="h4" component="h2" gutterBottom>
          My Collection
        </Typography>
        <Typography variant="body1" component="p">
          Loading collection...
        </Typography>
      </Box>
    );
  }

  return (
    <Box my={4}>
      <Typography variant="h4" component="h2" gutterBottom>
        My Collection
      </Typography>
      <FormControl variant="outlined" fullWidth margin="normal">
        <InputLabel id="filter-by-system-label">Filter by System</InputLabel>
        <Select
          labelId="filter-by-system-label"
          value={selectedSystem}
          onChange={handleSystemFilterChange}
          label="Filter by System"
        >
          <MenuItem value="">
            <em>All Systems</em>
          </MenuItem>
          {systems.map(system => (
            <MenuItem key={system.id} value={system.id}>
              {system.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {collection ? (
        <>
          <Typography variant="h5" component="h3" gutterBottom>
            {collection.name}
          </Typography>

          <Box mt={4}>
            <Typography variant="h6" component="h4" gutterBottom>
              Systems ({sortedSystems.length})
            </Typography>
            {sortedSystems.length > 0 ? (
              <Grid container spacing={3}>
                {sortedSystems.map((detail, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6" component="div">
                          {detail.name}
                        </Typography>
                        <Typography color="text.secondary">
                          Manufacturer: {detail.manufacturer}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button variant="outlined" color="secondary" onClick={() => handleDelete(detail.collectionItemId)}>
                          Delete from Collection
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography variant="body1" component="p">
                There are no systems in your collection yet.
              </Typography>
            )}
          </Box>

          <Box mt={4}>
            <Typography variant="h6" component="h4" gutterBottom>
              Games ({sortedGames.length})
            </Typography>
            <TextField
              fullWidth
              label="Search Games"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              sx={{ marginBottom: 2 }}
            />
            <FormControl variant="outlined" sx={{ minWidth: 120, marginRight: 2 }}>
              <InputLabel id="order-by-label">Order By</InputLabel>
              <Select
                labelId="order-by-label"
                value={orderBy}
                onChange={handleOrderByChange}
                label="Order By"
              >
                <MenuItem value="title">Title</MenuItem>
                <MenuItem value="developer">Developer</MenuItem>
                <MenuItem value="publisher">Publisher</MenuItem>
                <MenuItem value="year">Year</MenuItem>
                <MenuItem value="systemName">System</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" sx={{ minWidth: 120, mb: 3 }}>
              <InputLabel id="order-direction-label">Direction</InputLabel>
              <Select
                labelId="order-direction-label"
                value={orderDirection}
                onChange={handleOrderDirectionChange}
                label="Direction"
              >
                <MenuItem value="asc">A-Z / Ascending</MenuItem>
                <MenuItem value="desc">Z-A / Descending</MenuItem>
              </Select>
            </FormControl>
            {sortedGames.length > 0 ? (
              <Grid container spacing={3}>
                {sortedGames.map((detail, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6" component="div">
                          {detail.title}
                        </Typography>
                        <Typography color="text.secondary">
                          Developer: {detail.developer}
                        </Typography>
                        <Typography color="text.secondary">
                          Publisher: {detail.publisher}
                        </Typography>
                        <Typography color="text.secondary">
                          Year: {detail.year}
                        </Typography>
                        <Typography color="text.secondary">
                          System: {detail.systemName}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button variant="outlined" color="secondary" onClick={() => handleDelete(detail.collectionItemId)}>
                          Delete from Collection
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography variant="body1" component="p">
                There are no games in your collection yet.
              </Typography>
            )}
          </Box>
        </>
      ) : (
        <Typography variant="body1" component="p">
          Error: Could not fetch collection.
        </Typography>
      )}
    </Box>
  );
};

export default MyCollection;
