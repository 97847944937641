// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { loginUser } from './firebase-utils';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const signInWithGoogle = (onSuccess) => {
  signInWithPopup(auth, provider)
    .then(async (result) => {
      const user = result.user;
      const userData = {
        displayName: user.displayName,
        email: user.email,
        uid: user.uid,
      };

      try {
        // Send the user data to your backend
        await loginUser(userData);
        console.log('User data upserted successfully');
        if (onSuccess) {
          onSuccess(result);
        }
      } catch (error) {
        console.error('Failed to upsert user data', error);
      }
    })
    .catch((error) => {
      console.error('Error during sign-in:', error);
    });
};

const signOutUser = () => signOut(auth);

export { auth, signInWithGoogle, signOutUser };
