// src/pages/Profile.js
import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, List, ListItem, ListItemText, IconButton, Switch } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { LightMode, DarkMode } from '@mui/icons-material';
import { useTheme } from '../ThemeContext';
import { fetchUserByUid } from '../firebase-utils';

const Profile = () => {
  const { currentUser } = useAuth();
  const { theme, toggleTheme } = useTheme();
  const isLightTheme = theme === 'light';

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUserData = async () => {
      if (currentUser) {
        try {
          const data = await fetchUserByUid(currentUser.uid);
          setUserData(data);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
        setLoading(false);
      }
    };
    getUserData();
  }, [currentUser]);

  const handleToggleTheme = async () => {
    toggleTheme();
    if (userData) {
      setUserData({ ...userData, theme: isLightTheme ? 'dark' : 'light' });
    }
  };

  if (loading) {
    return <Typography variant="h6" component="p">Loading...</Typography>;
  }

  return (
    <Box my={4}>
      <Typography variant="h1" component="h2" gutterBottom>
        My Profile
      </Typography>
      <Typography variant="body1" component="p">
        Here you can view your profile information.
      </Typography>

      {userData ? (
        <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
          <List>
            <ListItem>
              <ListItemText primary="Display Name" secondary={userData.displayName || 'N/A'} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Email" secondary={userData.email || 'N/A'} />
            </ListItem>
            <ListItem>
              <ListItemText primary="UID" secondary={userData.uid} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Theme" secondary={userData.theme || 'N/A'} />
            </ListItem>
            <ListItem>
              <IconButton color="inherit" onClick={handleToggleTheme} sx={{ mr: 1 }}>
                {isLightTheme ? <DarkMode /> : <LightMode />}
              </IconButton>
              <Switch checked={!isLightTheme} onChange={handleToggleTheme} color="default" />
            </ListItem>
          </List>
        </Paper>
      ) : (
        <Typography variant="body1" component="p" color="error">
          No user information available.
        </Typography>
      )}
    </Box>
  );
};

export default Profile;
